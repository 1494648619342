.navbar{
    height: 100%;
    width: 100%;
    opacity: 0.93;
    font-size: 40px;
    font-family: 'Silkscreen', cursive;
    margin-top: 5%;
    justify-content: center;
    
}
.containter{
    
}


.navbar:hover{
  opacity: 1;
}

.navBarLink {
    display: inline-block;
    position: relative;
  }
  
  .navBarLink::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 5px;
    bottom: 0;
    left: 0;
    background-color:wheat;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .navBarLink:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }