* {
  text-align: center;
 }

body{
  background-image: linear-gradient(to bottom,
   #C02FD0 , white 80% );
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  font-family: 'Silkscreen', cursive;
  font-weight: bold;
}
.App-header {
  background: #336FCC;
  min-height: 30%vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


